import React from 'react';
import { NavModel } from '@grafana/data';
import Page from 'app/core/components/Page/Page';
import { connector } from './DataSourceCache';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';

export type Props = { navModel: NavModel };

export const DataSourceUpgradePage = ({ navModel }: Props) => {
  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'With Query Caching, you can load dashboards faster and reduce costly queries to data sources by temporarily storing query results in memory, Redis, or Memcachd.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(DataSourceUpgradePage);
