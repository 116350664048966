import React from 'react';
import { Permissions } from 'app/core/components/AccessControl';
import { connect, ConnectedProps } from 'react-redux';

import Page from 'app/core/components/Page/Page';
import { getNavModel } from 'app/core/selectors/navModel';
import { getDataSourceLoadingNav } from 'app/features/datasources/state/navModel';
import { GrafanaRouteComponentProps } from 'app/core/navigation/types';
import { contextSrv } from 'app/core/core';
import { AccessControlAction } from '../../types';
import { AccessControlAction as EnterpriseActions } from '../types';

interface RouteProps extends GrafanaRouteComponentProps<{ id: string }> {}

function mapStateToProps(state: any, props: RouteProps) {
  const dataSourceId = parseInt(props.match.params.id, 10);
  const dataSourceLoadingNav = getDataSourceLoadingNav('permissions');
  return {
    resourceId: dataSourceId,
    navModel: getNavModel(state.navIndex, `datasource-permissions-${dataSourceId}`, dataSourceLoadingNav),
  };
}

export const connector = connect(mapStateToProps);
export type Props = ConnectedProps<typeof connector>;

export const DataSourcePermissions = ({ resourceId, navModel }: Props) => {
  const canListUsers = contextSrv.hasPermission(AccessControlAction.OrgUsersRead);
  const canSetPermissions = contextSrv.hasPermission(EnterpriseActions.DataSourcesPermissionsWrite);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <Permissions
          resource="datasources"
          resourceId={resourceId}
          canListUsers={canListUsers}
          canSetPermissions={canSetPermissions}
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(DataSourcePermissions);
