import React, { useCallback } from 'react';
import { LinkButton } from '@grafana/ui';
import { urlUtil } from '@grafana/data';
import { featureEnabled, reportExperimentView } from '@grafana/runtime';
import { getVariablesUrlParams } from 'app/features/variables/getAllVariableValuesForUrl';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';
import { ShareModalTabProps } from 'app/features/dashboard/components/ShareModal';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { ProBadge } from 'app/core/components/Upgrade/ProBadge';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const CreateReportTab = ({ dashboard, onDismiss }: ShareModalTabProps) => {
  const getUrl = () => {
    const variablesQuery = urlUtil.toUrlParams(getVariablesUrlParams());
    const timeRangeUrl = urlUtil.toUrlParams(getTimeSrv().timeRangeForUrl());

    return `?${variablesQuery}&${timeRangeUrl}&db-uid=${dashboard.uid}&db-id=${dashboard.id}&db-name=${dashboard.title}`;
  };

  if (!featureEnabled('reports.creation')) {
    reportExperimentView(buildExperimentID('reporting-tab'), ExperimentGroup.Test, '');
    return (
      <UpgradeBox text="With Reporting you can deliver dashboard PDFs by email on a schedule to users who don't regularly look at Grafana." />
    );
  }

  return (
    <LinkButton href={`reports/new/${getUrl()}`} onClick={onDismiss}>
      Create report using this dashboard
    </LinkButton>
  );
};

export const ReportTabProBadge = (): JSX.Element => {
  const memoizedCallback = useCallback(() => {
    reportExperimentView('reporting-tab-badge', ExperimentGroup.Test, '');
  }, []);
  return <ProBadge onLoad={memoizedCallback} />;
};
