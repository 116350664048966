import React from 'react';
import { NavModel } from '@grafana/data';
import Page from 'app/core/components/Page/Page';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { connector } from './AccessControlDataSourcePermissions';

export type Props = { navModel: NavModel };

export const UpgradePage = ({ navModel }: Props) => {
  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox
          text={
            'With data source permissions, you can protect sensitive data by limiting access to this data source to specific users, teams, and roles.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(UpgradePage);
