import { featureEnabled } from '@grafana/runtime';
import { addDashboardShareTab, ShareModalTabModel } from 'app/features/dashboard/components/ShareModal';
import { contextSrv } from 'app/core/services/context_srv';
import { config } from 'app/core/config';
import { SharePDF } from './SharePDF';
import { CreateReportTab, ReportTabProBadge } from './CreateReportTab';
import { AccessControlAction } from '../types';

const sharePDFTab: ShareModalTabModel = {
  label: 'PDF',
  value: 'pdf',
  component: SharePDF,
};

const createReportTab: ShareModalTabModel = {
  label: 'Report',
  value: 'report',
  tabSuffix: config.featureHighlights.enabled && !featureEnabled('reports.creation') ? ReportTabProBadge : undefined,
  component: CreateReportTab,
};

export function initReporting() {
  if (featureEnabled('reports.creation')) {
    addDashboardShareTab(sharePDFTab);

    if (contextSrv.hasAccess(AccessControlAction.ReportingAdminCreate, contextSrv.hasRole('Admin'))) {
      addDashboardShareTab(createReportTab);
    }
  } else if (config.featureHighlights.enabled) {
    addDashboardShareTab(createReportTab);
  }
}
